@use 'sass:map';

.create-post-modal {
  .modal-dialog {
    max-width: 540px;
  }
  
  .modal-content {
    padding: 0 12px;
    border-radius: 10px;
  }
  
  .modal-header {
    border: 0;
    // padding-bottom: 0;
  }

  .modal-footer {
    border: 0;
  }

  .modal-body {
    padding: 0 12px;

    .title-section {
      margin-bottom: 24px;
    }
  }
  .modal-backdrop {
    opacity: 0.7;
  }
}