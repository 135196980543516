// @import '~antd/dist/antd.css';
@import "./styles/main.scss";

html, body {
  min-height: 100%;
}

#root {
  height: 100%;
}

.App {
  // text-align: center;
  height: 100vh;
  overflow: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.training-index-left {
  max-width: 378px;

  .bottom-section {
    max-width: 276px;
  }
}

.training-index-right {
  padding-left: 33px;
}

.training-overview-left  {
  // @media screen and (min-width: 960px) {
  //   max-width: 684px;
  // }
}

// Community 
.community-search {
  max-width: 582px;
}


.sticky-div {
  z-index: 999;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

