@use 'sass:map';

.hero-section {
  // background-image: url('../../assets/images/home-hero-1.svg');
  background-repeat: no-repeat;
  background-size: 480px 360px;
  background-position: right 0px top 36px;
  padding-top: 72px;
  
  
  @media screen and (min-width: $small) {
    background-image: none;
    padding-bottom: 16px;
    padding-top: 40px;
  }

  @media screen and (min-width: $medium) {
    padding-bottom: 72px;
    padding-top: 72px;
  }


  h1 {
    max-width: 674px;
    margin-bottom: 20px;
  }
  p {
    max-width: 576px;
  }
  .actions {
    margin-top: 36px;
    max-width: 576px;
  }

  div {
    button {
      @media screen and (min-width: $medium) {
        margin-right: 16px;
      }
      
      @media screen and (max-width: $small) {
        margin-right: 0;
      }
    }
  }

  img {
    // max-width: 320px;
  }

  .home-bg-xs {
    background: #FAFAFA;
    // border-bottom: 1px solid map-get($theme, default-200);
    margin: 32px 0px;
    min-height: 242px;
    background-image: url("../../assets/images/header.png");
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media screen and (min-width: $small) {
    .d3 {
      font-size: 28px !important;
    }
    .p3 {
      font-size: 18px;
    }
  }

  @media screen and (min-width: $medium) {
    .d3 {
      font-size: 36px !important;
    }
    .p3 {
      font-size: 18px;
    }
  }

}