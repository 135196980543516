
@use 'sass:map';



.site-name {
  color: map.get($theme, site-name-color) !important;
  font-family: "PoppinsSemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  max-width: 225px;
  overflow-wrap: break-word;
  white-space: normal !important;
  text-align: left;
}
.thumbnail-image {
  width: 34px;
height: 34px;
border-radius: 50%;
}
.navbar-main {
  @include spacing-horizontal;
  @media screen and (min-width: $small) {
    min-height: 64px;
  }

  @media screen and (min-width: $medium) {
    height: 88px;
  }

  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    height: 60px;
  }

  .navbar-nav {
    a.active {
      @include sans-serif-regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: map.get($theme, primary-500);
    }
  }

}

.dropdown-menu {
  width: fit-content;
  min-width: fit-content;
  border-radius: 4px;
  .dropdown-item.active {
    background: none;
  }
}

.nav-profile-name {
  .dropdown-menu {
    margin-left: -70px !important;
  }
}

.navbar-light {
  box-shadow: 0px 4px 12px rgba(104, 115, 125, 0.1);
  .navbar-nav {
    .nav-link {
      color: map.get($theme, typescale);
      font-family: "PoppinsRegular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      margin: 0px 10px;

    }
    .nav-link:hover {
      color: map.get($theme, primary-500);
    }
    .nav-link.active {
      @include sans-serif-regular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: map.get($theme, primary-500);
    }
  }
  .navbar-toggler {
    border: none;
  }

  div.nav-profile-name {
    align-self: center;
    a {
      color: map.get($theme, typescale);
      display: flex;
      align-items: center;
    }

    a:hover {
      color: map.get($theme, primary-500);
    }
  }

}

.bg-light {
  background-color: map.get($theme, background) !important;
}


// .sticky-top{
//   top:56px;
// }

.mobile-view-dropdown {
 width: 100%;
 a{
   justify-content: center;
 }
 .dropdown-menu {
   width: 100%;
   margin-left: 0 !important;
 }
}

.search-img {
   @media screen and (min-width: $medium) {
    margin: 0 !important;
  }
}