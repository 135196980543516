
@use 'sass:map';

$small: 320px;
$medium: 900px;

@mixin font-family-source-sans {
  font-family: "SourceSansProRegular";
  font-style: normal;
  font-weight: normal;
}

@mixin spacing-horizontal {
  // padding-left: 71px;
  // padding-right: 71px;
}

@mixin sans-serif-regular {
  font-family: "PoppinsRegular";
}

@mixin placeholder {
  ::placeholder               {@content}
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}  
}

@mixin d1 {
  font-family: 'PoppinsRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 48px;
  /* or 133% */
  /* Gray / 900 */
  color: map.get($theme, default-900);
}

.d1 {
  @include d1;
}

.d2 {
  @include d1;
  font-size: 18px;
}

.d3 {
  font-family: "PoppinsSemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
}

h1 {
  /* Gray / 800 */
  color: map.get($theme, typescale);
  @media screen and (min-width: $small) {
    /* H1 Mobile */
    font-family: "PoppinsSemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    /* identical to box height, or 117% */
  }
  @media screen and (min-width: $medium) {
    /* Display 1 */
    font-family: "PoppinsMedium";
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 72px;
    /* identical to box height, or 112% */
  }
}

h2 {
  /* Gray / 800 */
  color: map.get($theme, typescale);
  @media screen and (min-width: $small) {
    /* H2 Mobile */
    font-family: "PoppinsSemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 117% */
  }
  @media screen and (min-width: $medium) {
    font-family: "PoppinsSemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
  }
}

h3 {
  /* Gray / 800 */
  color: map.get($theme, typescale);
  word-break: break-all;
  @media screen and (min-width: $small) {
    /* H3 Mobile */
    font-family: "PoppinsSemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    /* identical to box height, or 117% */
  }
  @media screen and (min-width: $medium) {
    font-family: "PoppinsSemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
  }
}

.about-top-section h3 {
  font-family: "PoppinsSemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
}

h4 {
  /* Gray / 800 */
  display: block;
  // color: map.get($theme, typescale);
  /* H4/Semibold */
  font-family: "PoppinsSemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */
}

@mixin h4-regular {
  /* Gray / 800 */
  display: block;
  color: map.get($theme, typescale);
  /* H4/Semibold */
  font-family: "PoppinsRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */
}

.h4-regular {
  @include h4-regular;
}

.h4-regular-small {
  @include h4-regular;
  font-size: 18px;
}

@mixin h5 {
  /* Gray / 800 */
  color: map.get($theme, typescale);
  /* H5 */
  font-family: "PoppinsSemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 150% */
}

h5 {
  @include h5
}

@mixin h6-regular {
  /* Gray / 800 */
  color: map.get($theme, typescale);
  /* H6/Medium */
  font-family: "PoppinsRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin h6 {
  /* Gray / 800 */
  color: map.get($theme, typescale);
  /* H6/Medium */
  font-family: "PoppinsMedium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  word-break: break-all;
  // white-space: nowrap;
}

h6 {
  @include h6
}

.p1 {
  color: map.get($theme, typescale);
  @media screen and (min-width: $small) {
    /* P1 Moble */
    font-family: "PoppinsRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
  }
  @media screen and (min-width: $medium) {
    /* P1 */
    font-family: "PoppinsRegular";
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    /* or 160% */
  }
}

@mixin p2 {
  /* P2 */
  color: map.get($theme, typescale);
  font-family: "SourceSansProRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
}

.p2 {
  @include p2;
}


@mixin p3 {
  /* P3 */
  color: map.get($theme, typescale-light);
  font-family: "SourceSansProRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  // max-width: 684px;
  /* or 150% */
}

.p3 {
  @include p3
}

@mixin p4 {
  /* P4 */
  color: map.get($theme, typescale-light);
  font-family: "SourceSansProRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
  /* or 143% */
}

.p4 {
  @include p4
}

@mixin s1 {
  /* S1 */
  color: map.get($theme, typescale);
  font-family: "SourceSansProRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */
}

.s1 {
  @include s1
}

.c1 {
  /* C1 */
  font-family: "PoppinsSemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 117% */
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

@mixin buttons-1 {
  font-family: "PoppinsSemiBold";
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

@mixin buttons-2 {
  font-family: "PoppinsMedium";
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

@mixin buttons-3 {
  font-family: "PoppinsRegular";
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}


// Elements

@mixin button-text {
  color: map.get($theme, button-primary-text);
}

@mixin button-outline-text {
  color: map.get($theme, button-outline-text);
}

@mixin button-large {
  padding: 10px 24px;
  border-radius: 12px;
  @include buttons-1
}

@mixin button-small {
  padding: 2px 16px;
  border-radius: 8px;
  font-family: "PoppinsMedium";
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

@mixin button-medium {
  padding: 6px 16px;
  border-radius: 8px;
  font-family: "PoppinsMedium";
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

.btn-lg {
  @include button-large;
}

.btn-sm {
  @include button-small;
}

.btn-md {
  @include button-medium;
}


@mixin button-layout {
  // width: 100%;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
}

.button-primary {
  /* Auto Layout */
  @include button-layout;

  /* Purple / 500 */
  background: map.get($theme, button-primary-bg);
  border: 0;
  @include button-text;
}

.button-primary:hover {
  /* Purple / 600 */
  background: map.get($theme, button-primary-bg-hover);
}

.button-primary:focus {
  /* Purple / 700 */
  background: map.get($theme, button-primary-bg-focus);
}

.button-primary:disabled {
  background: map.get($theme,button-disabled-bg);
}

.button-outline {
  /* Auto Layout */
  @include button-layout;
  background: map.get($theme, background);
  /* Purple / 500 */

  border: 1px solid map.get($theme, button-outline-border-color);
  box-sizing: border-box;
  /* Button / Light Shadow */

  box-shadow: 0px 2px 4px rgba(1, 88, 226, 0.1);
  border-radius: 12px;
  @include button-outline-text;
}


.button-outline:hover {
  /* Purple / 600 */
  background: map.get($theme, button-outline-bg-hover);
  // opacity: 25%;
}

.button-outline:focus {
  /* Purple / 700 */
  background: map.get($theme, button-outline-bg-focus);
  // opacity: 50%;
}

.button-outline:disabled {
  background: map.get($theme, button-disabled-bg);
  border: 0;
  color: map.get($theme, background);
}


.button-tertiary {
  /* Auto Layout */
  @include button-layout;
  background: map.get($theme, background);
  /* Purple / 500 */

  border: none;
  // box-sizing: border-box;
  /* Button / Light Shadow */

  // box-shadow: 0px 2px 8px rgba(158, 59, 171, 0.1);
  border-radius: 12px;
  @include button-outline-text;
}

.button-tertiary:hover {
  /* Purple / 600 */
  background: map.get($theme, button-outline-bg-hover);
  // opacity: 25%;
}

.button-tertiary:focus {
  /* Purple / 700 */
  background: map.get($theme, button-outline-bg-focus);
  // opacity: 50%;
}

.button-tertiary:disabled {
  // background: map.get($theme, button-disabled-bg);
  border: 0;
  color: map.get($theme, button-disabled-text);
}

.bg-highlight {
  background: map.get($theme, highlight-bg)
}